body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.mainHeaderStyle {
  padding-left: 20px;
}
.mainTitleFont {
  font-weight: bold;
}

.paddingStyle {
    padding: 20px;
    padding-right: 20px;
}

.containerStyle {
    border: 10px solid black;
}

.imgStyle {
  margin-bottom: 80px;
  border: 10px solid black;
}

.pageIndexImage {
    margin: 20px;
    margin-top: 40px;
    cursor: pointer;
    transition: 0.3s linear;
    border-style: solid;
    border-width: 10px;
}

.pageTempTitleBorder {
  margin-top: 85px;
  padding: 10px;
  border-bottom: 10px solid black; 
}

.pageTempImg img {
  vertical-align: middle;
  width: 100%;
  height: auto;
}

.headerStyle {
  margin-top: 20px;
}

.titleStyle {
  color: black;

}
.titleStyle:hover {
  color: black
}

.pageHeaderSty {
  margin-bottom: 20px;
}

.pageProjectStyle {
  padding: 80px;
}

/*
/* Contact Page Style
/* */
.contactBodyStyle {
  margin: 20px;
  border: 10px solid black;
}

.contactStyle {
  text-align: center;
}

.contactStyleSpace {
  margin-top: 100px;
}

.contactLinkStyle {
  color: black;

}
.contactLinkStyle:hover {
  color: black;

}
.contactLinkStyle h3 {
  font-size: 14px;
  padding-left: 90px;
}
.contContactStyle {
  margin: 70px 0;
    padding-bottom: 50px;
}
.contactParStyle {
  padding-top: 30px;
  padding-left: 170px;
}
/*
/* Button Style
/* */
.btnStyle {
  margin: 30px;
}

.btnStyle:hover {
  color: black;
  font-weight: bold;
}

/*
/* Footer Style
/* */
.footer {
  text-align: center;
  padding: 20px;
  color: white;
  background-color: black;
}

#rowStyle {
  padding: 0;
}

/*
/* Gallery Wall Style
/* */
.row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
  }

  /* Create four equal columns that sits next to each other */
  .column {
    flex: 25%;
    max-width: 33.3%;
    padding: 0 4px;

  }
  
  .column img {
    vertical-align: middle;
    width: 100%;
    /* filter: grayscale(1) brightness(0.5); */
    cursor: pointer;
    transition: 0.3s linear;
  }
  .column img:hover {
    filter: grayscale(0);
  }
  @media screen and (max-width: 800px) {
    .column {
      flex: 50%;
      max-width: 50%;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      flex: 100%;
      max-width: 100%;
    }
    .column img {
      filter: grayscale(0) brightness(1);
    }
  }


